@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  color-scheme: dark;
  font-family: var(--regular);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dark-blue: #01004c;
  --blue: #0264d1;
  --peach: #ff8e8a;
  --dark-pink: #c2007f;

  --grey: #8b8b8b;

  --regular: 'TitilliumWeb-Regular', sans-serif;
  --bold: 'TitilliumWeb-Bold', sans-serif;
  --light: 'TitilliumWeb-Light', sans-serif;
}

html {
  cursor: none !important;
}

a {
  cursor: none !important;
  text-decoration: none;
  color: inherit;
  text-decoration: inherit;
}

body {
  color: var(black);
  background-color: var(white);
}

#pointer-ring {
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  padding: 15px;
  border: 2px solid black;
  position: absolute;
  border-radius: 100px;
  z-index: 2;
  pointer-events: none;
}

.line-gradient {
  background: var(--dark-pink);
  background: linear-gradient(-90deg, var(--dark-pink) 0%, rgba(60, 51, 80, 0) 100%);
  background: -webkit-linear-gradient(
    -90deg,
    var(--dark-pink) 0%,
    rgba(60, 51, 80, 0) 100%
  );
}

.green-pink-gradient {
  background: "#00cea8";
  background: linear-gradient(90.13deg, #3df4de 1.9%, #c2007f 97.5%);
  background: -webkit-linear-gradient(-90.13deg, #3df4de 1.9%, #c2007f 97.5%);
}

.black-gradient {
  background: #000000; 
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  );
  background: linear-gradient(
    to right,
    #434343,
    #000000
  );
}


.green-text-gradient {
  background: #11998e;
  background: -webkit-linear-gradient(
    to top,
    #11998e,
    #38ef7d
  );
  background: linear-gradient(
    to top,
    #11998e,
    #38ef7d
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blue-text-gradient {
  background: #56ccf2;
  background: -webkit-linear-gradient(
    to top,
    #2f80ed,
    #56ccf2
  );
  background: linear-gradient(
    to top,
    #2f80ed,
    #56ccf2
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pink-text-gradient {
  background: var(--dark-pink);
  background: -webkit-linear-gradient(
    to top,
    var(--dark-pink),
    #fc6767
  );
  background: linear-gradient(
    to top,
    var(--dark-pink),
    #fc6767
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hash-span {
  margin-top: -100px;
  padding-bottom: 100px;
  display: block;
}